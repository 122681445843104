.dropzone {
    /* text-align: center; */
    padding: 20px;
    border: 1px dashed #707070;
    color: #7A7A7A;
    width:100%;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .selected-file-wrapper {
    text-align: center;
  }
  
  .selected-file {
    color: #000; 
    font-weight: bold;
  }
  