#download-btn {
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    width: fit-content;
    background: linear-gradient(to Right, #ffbc00, #ffd050, #ffbc00);
    color: #280575;
    margin-top: 20px;
}
a {
    text-decoration: none;
}
body:has(#download-btn) {
    /* margin-top: 0px; */
    font-family: "poppins";
    background-image: url(./background.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#float-btn {
    /* background: transparent; */
    /* margin-top: 10px;
    margin-right: 20px;
    position: absolute;
    right: 0;
    height: 60px;
    top: 0;
    width: 60px;
    border-radius: 50%;
    background-color: #ffbc00; */
}
/* Default styles for header logo */
/* .header-logo {
    object-fit: cover;
    background-color:#230d4b;
    width: 100%;
    height: 62px;
    padding: 40px 40px 40px 40px !important;
  }
  
  /* Media query for smaller screens */
  
  
  .navbar {
    display: flex;
    position: absolute !important;
    width: 100%;
    top:0px;
    left: 0px;
    justify-content: space-between;
    align-items: center;
    background-color: #230d4b;
    
    padding: 10px;
}

.navbar .home-link {
    background-color: #11012f;
    border-radius: 40%;
    color: white;
    margin-left: 10px;
    text-decoration: none;
    padding: 10px 15px;
}

.logo, .right-image {
    margin-right: 10px;
    height: 50px; /* Adjust based on your logo and right image size */
}
