body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
  
  @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
  
  @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap");
  /* Scrollbars */
  * {
    scrollbar-width: thin;
    scrollbar-color: #adadad #f5f5f5;
  }
  
  .bgWhiteScrollbar {
    scrollbar-color: #adadad #ffffff;
  }
  
  .bgGrayScrollbar {
    scrollbar-color: #adadad #f5f5f5;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 20px;
    border: 3px solid #f5f5f5;
  }
  
  .bgWhiteScrollbar::-webkit-scrollbar-track {
    background: #ffffff;
  }
  
  .bgGrayScrollbar::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  
  .bgWhiteScrollbar::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 20px;
    border: 3px solid #ffffff;
  }
  
  .bgGrayScrollbar::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 20px;
    border: 3px solid #f5f5f5;
  }
  
  /* Hides scrollbar in every screen*/
  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hides Scrollbar in phone and touch screens*/
  @media (max-width: 991px) {
    .hide-scroll-bar-phone {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .hide-scroll-bar-phone::-webkit-scrollbar {
      display: none;
    }
  }
  
  /* Stats Card Percentage Section */
  .circle-wrap {
    margin: 5rem auto 2rem auto;
    width: 11rem;
    height: 11rem;
    background: #fafafa;
    border-radius: 50%;
    position: relative;
  }
  
  @media (max-width: 991px) {
    .circle-wrap {
      margin: 2rem auto 1rem auto;
    }
  }
  
  .circle-wrap .circle .mask,
  .circle-wrap .circle .fill {
    width: 11rem;
    height: 11rem;
    position: absolute;
    border-radius: 50%;
  }
  
  .circle-wrap .circle .mask {
    clip: rect(0, 11rem, 11rem, 5.5rem);
  }
  
  .circle-wrap .circle .mask .fill {
    clip: rect(0, 5.5rem, 11rem, 0);
  }
  
  .circle-wrap .circle .mask.full45,
  .circle-wrap .circle .fill45 {
    animation: fill45 ease-in-out 3s;
    transform: rotate(81deg);
  }
  
  @keyframes fill45 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(81deg);
    }
  }
  
  /* for 10% */
  .circle-wrap .circle .mask.full10,
  .circle-wrap .circle .fill10 {
    animation: fill10 ease-in-out 3s;
    transform: rotate(20deg);
  }
  
  @keyframes fill10 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(20deg);
    }
  }
  
  /* for 25% */
  .circle-wrap .circle .mask.full25,
  .circle-wrap .circle .fill25 {
    animation: fill25 ease-in-out 3s;
    transform: rotate(45deg);
  }
  
  @keyframes fill25 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  
  /* for 50% */
  .circle-wrap .circle .mask.full50,
  .circle-wrap .circle .fill50 {
    animation: fill50 ease-in-out 3s;
    transform: rotate(90deg);
  }
  
  @keyframes fill50 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
  
  /* for 60% */
  .circle-wrap .circle .mask.full60,
  .circle-wrap .circle .fill60 {
    animation: fill60 ease-in-out 3s;
    transform: rotate(108deg);
  }
  
  @keyframes fill60 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(108deg);
    }
  }
  
  /* for 75% */
  .circle-wrap .circle .mask.full75,
  .circle-wrap .circle .fill75 {
    animation: fill75 ease-in-out 3s;
    transform: rotate(135deg);
  }
  
  @keyframes fill75 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  
  /* for 80% */
  .circle-wrap .circle .mask.full80,
  .circle-wrap .circle .fill80 {
    animation: fill80 ease-in-out 3s;
    transform: rotate(150deg);
  }
  
  @keyframes fill80 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(150deg);
    }
  }
  
  /* for 90% */
  .circle-wrap .circle .mask.full90,
  .circle-wrap .circle .fill90 {
    animation: fill90 ease-in-out 3s;
    transform: rotate(165deg);
  }
  
  @keyframes fill90 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(165deg);
    }
  }
  
  /* for 100% */
  .circle-wrap .circle .mask.full100,
  .circle-wrap .circle .fill100 {
    animation: fill100 ease-in-out 3s;
    transform: rotate(180deg);
  }
  
  @keyframes fill100 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  
  .slick-dots {
    bottom: -5px !important;
  }
  
  .slick-dots li button::before {
    font-size: 45px !important;
    content: "_" !important;
    font-weight: bold;
  }
  
  /* Mentor Section Overlay Effect */
  .backgroundImage {
    background-image: linear-gradient(
        to right bottom,
        rgba(70, 25, 149, 0.8),
        rgba(70, 25, 149, 0.8)
      ),
      url(https://firebasestorage.googleapis.com/v0/b/aankalan-learning.appspot.com/o/YoutubePlayButton.png?alt=media&token=45aa9a1a-9ce1-4be9-b8af-651033539322);
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .feature-section {
    position: relative;
  }
  
  .feature-section::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70%;
    background: url("./Assets/Images/FeatureBackground.svg");
    background-size: cover;
  }
  
  .phone {
    width: 310px;
    height: 640px;
    /* border: 2px solid #ccc; */
    border-radius: 30px;
    position: relative;
    background: #fff;
  }
  
  .screen {
    height: 90%;
    width: 95%;
    border: 2px solid #edecef;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    overflow-y: scroll;
    border-radius: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  @media screen and (max-width: 576px) {
    .phone {
      width: 200px;
      height: 450px;
    }
  }
  
  @media screen and (min-width: 1700px) {
    .phone {
      width: 400px;
      height: 850px;
    }
  }
  
  .phone::before {
    content: "";
    position: absolute;
    top: 2.5%;
    left: 50%;
    border-radius: 3px;
    height: 6px;
    width: 80px;
    background: #ccc;
    transform: translate(-50%, -50%);
  }
  
  .screen::-webkit-scrollbar {
    display: none;
  }
  
  .screen > img {
    display: block;
    width: 100%;
  }
  
  .-bottom-1\/10 {
    bottom: -10%;
  }
  
  .bg-gradient {
    background: transparent
      radial-gradient(
        closest-side at 50% 50%,
        #4a18a2 0%,
        #230d4b 100%,
        #230d4b 100%
      )
      0% 0% no-repeat padding-box;
  }
  
  .bg-oval-image {
    background-image: url("./Assets/Images/OvalGradient.svg");
    background-repeat: no-repeat;
    z-index: 100;
  }
  
  .onboarding-bg-img {
    background: transparent url("./Assets/Images/Onboading-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .app-screen-slider .swiper-slide-active {
    transform: scale(1.4);
    transition: 0.4s;
  }
  .app-screen-slider .swiper {
    overflow: visible;
  }
  
  .swiper-button-next::after {
    color: white;
  }
  .swiper-button-prev::after {
    color: white;
  }
  
  /* type writer */
  .blink {
    animation: blink 0.5s infinite;
  }
  @keyframes blink {
    to {
      opacity: 0;
    }
  }
  
  /* testimonial Card *.w-8\/10-center*/
  .testimonial-card-description::before {
    content: "“";
    position: absolute;
    color: #ffffff;
    left: 10%;
    top: 8%;
    font-size: 48px;
  }
  .testimonial-card-description::after {
    content: "”";
    position: absolute;
    color: #ffffff;
    right: 10%;
    bottom: 5%;
    font-size: 48px;
  }
  
  /* About us Aankalan */
  .ourservice-description::before {
    content: '"';
    color: #ffffff;
    font-size: 128px;
  }
  
  .gallery-images:hover {
    transform: scale(1.1);
  }
  
  .custom-textarea {
    resize: none;
  }
  
  /* Hide input arrows in number input*/
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  @media (max-width: 1200px) {
    .ourservice-description::before {
      font-size: 100px;
    }
  }
  
  @media (max-width: 992px) {
    .ourservice-description::before {
      font-size: 80px;
    }
  }
  
  @media (max-width: 576px) {
    .ourservice-description::before {
      font-size: 60px;
    }
  }
  
  /* combined classes  */
  
  .w-8\/10-center {
    width: 80%;
    @apply mx-auto py-10 sm:py-24;
  }
  .w-9\/10-left {
    width: 90%;
    @apply ml-auto py-10 sm:py-24;
  }
  
  .w-8\/10-center-without-py {
    width: 80%;
    @apply mx-auto;
  }
  .w-9\/10-left-without-py {
    width: 90%;
    @apply ml-auto;
  }
  
  .create-contest-padding-left {
    @apply pl-24 xl:pl-32 py-24;
  }
  
  @media (max-width: 576px) {
    .create-contest-padding-left {
      @apply px-10 py-14;
    }
  }
  
  .create-contest-padding-right {
    @apply pr-24 xl:pr-32 py-24;
  }
  
  /* Ellipse message container */
  .ellipse-message-container {
    border-radius: 50%;
  }
  
  /* congratulations page */
  .clip-section-left {
    clip-path: polygon(0 0, 60% 0, 40% 100%, 0 100%);
  }
  
  .clip-section-right {
    clip-path: polygon(50% 50%, 52% 50%, 52% 100%, 40% 100%);
  }
  
  /* Date Picker */
  .nice-dates-navigation,
  .nice-dates-day {
    color: #111;
  }
  .nice-dates-popover {
    width: 60% !important;
  }
  
  @media (max-width: 576px) {
    .nice-dates-popover {
      width: 80% !important;
    }
  }
  
  /* Time Picker */
  
  .react-time-picker__wrapper {
    border: none !important;
  }
  
  .react-time-picker__inputGroup > * {
    /* display: flex !important;
    justify-content: space-between !important;
    align-items: center !important; */
    /* color: red !important; */
    /* border: none !important; */
    outline-style: none !important;
  }
  
  .react-time-picker__inputGroup__hour .react-time-picker__inputGroup__minute {
    outline-style: none !important;
  }
  
  .time-picker {
    border-radius: 6px;
    @apply 3xl:text-xl px-4 w-full h-12 sm:h-14 3xl:h-16 bg-FAFAFA focus:border-461995 border border-D3D0D0 rounded-md focus:outline-none;
    padding-right: 5px;
  }
  
  /* Welcome Girl Animation */
  .bounce-girl {
    animation: bounceGirl 1s infinite;
  }
  @keyframes bounceGirl {
    0%,
    100% {
      transform: translateY(-5%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  
  /* Down Arrow Animation */
  .bounce-arrow {
    animation: bounceArrow 1s infinite;
  }
  @keyframes bounceArrow {
    0%,
    100% {
      transform: translateY(-35%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  
  .bounce-interaction {
    animation-name: bounceInteraction;
    animation-duration: 1s;
    animation-iteration-count: 2;
  }
  
  @keyframes bounceInteraction {
    0%,
    100% {
      transform: translateY(-10%);
      /* animation-timing-function: cubic-bezier(0.8, 0, 1, 1); */
    }
    50% {
      transform: translateY(0);
      /* animation-timing-function: cubic-bezier(0, 0, 0.2, 1); */
    }
  }
  
  /* Background image -> Create test series */
  .create-test-series-atf-bg {
    background: url("./Assets/Images/CreateTestSeriesAtf copy.png");
    background-size: contain;
    z-index: -1;
    background-repeat: no-repeat;
  }
  .create-contest-atf-bg {
    background: url("./Assets/Images/create-contest.png");
    background-size:cover;
    z-index: -1;
    background-repeat: no-repeat;
  }
  
  /* @media (min-width:1800px) {
    .w-8\/10-center ,.w-8\/10-center-without-py  {
      max-width:1400px;
    }
    .w-9\/10-left ,.w-9\/10-left-without-py {
      max-width:1600px;
    }
  } */
  
  /* .screen-padding {
    @apply px-9 sm:px-16 lg:px-14 xl:px-28 2xl:px-36 3xl:px-44   10 sm:py-24;
  } */
  /* .screen-left-padding {
    @apply pl-9 sm:pl-16 lg:pl-14 xl:pl-28 2xl:pl-36 3xl:pl-44;
  } */
  
  .heading {
    @apply font-roboto-slab font-bold text-base sm:text-xl md:text-2xl xl:text-4xl 3xl:text-5xl capitalize;
  }
  .heading2 {
    @apply font-source-sans-pro text-sm md:text-2xl;
  }
  .heading3 {
    @apply font-source-sans-pro text-xs sm:text-base md:text-xl;
  }
  .sub-heading {
    @apply font-source-sans-pro  text-sm xl:text-base 3xl:text-lg capitalize;
  }
  
  .card-content {
    @apply text-xs md:text-sm xl:text-base;
  }
  .card-heading {
    @apply text-sm md:text-lg 3xl:text-xl;
  }
  
  .overflow-without-scrollbar {
    @apply overflow-x-auto hide-scroll-bar overflow-y-hidden;
  }
  
  .flex-centerXY {
    @apply flex justify-center items-center;
  }
  
  .form-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: black;
    cursor: pointer;
  }
  
  .form-range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: black;
    cursor: pointer;
  }
  
  /* light placeholder  */
  light-placeholder::placeholder {
    color: red;
    opacity: 1; /* Firefox */
  }
  
  light-placeholder:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }
  
  light-placeholder::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }
  
  .create-contest-form::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: url("./Assets/Icons/Create-Contest/form-background.svg");
    background-size: cover;
  }
  
  .create-test-series::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: url("./Assets/Icons/background-clip.svg");
    background-size: cover;
  }
  
  table,
  th,
  td {
    border: 1px solid;
  }
  